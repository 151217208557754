import {
  Box,
  Button,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import {
  Abwesenheit,
  AbwesenheitInfo,
  Anwesenheit,
  AnwesenheitInfo,
  Entsorger,
  EntsorgerInfo,
} from "../../api";
import CheckIcon from "@mui/icons-material/Check";
import FilterBenutzerSelect from "../benutzer/FilterBenutzerSelect";
import { Field, Formik } from "formik";

export type AnwesenheitenFilter = {
  search?: string;
  mitarbeiterId?: string;
};

export type Props = Omit<DataRequest<AnwesenheitenFilter>, "filter"> &
  Partial<Pick<DataRequest<AnwesenheitenFilter>, "filter">>;

export default function UrlaubDataTable({ ...input }: Props) {
  const request = useDataRequest<AnwesenheitenFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <UrlaubResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<AnwesenheitenFilter>) {
  const [{ search, mitarbeiterId }, setField] = useDebouncedFilter(request);
  const setMitarbeiterId = (value: string) => {
    setField("mitarbeiterId", value);
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Formik
            initialValues={{ mitarbeiterId: mitarbeiterId }}
            onSubmit={() => {}}
          >
            <Field
              component={FilterBenutzerSelect}
              name={"mitarbeiterId"}
              label={"Mitarbeiter"}
              setBenutzer={setMitarbeiterId}
            ></Field>
          </Formik>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-urlaub");
            }}
          >
            Urlaub anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Formik
            initialValues={{ mitarbeiterId: mitarbeiterId }}
            onSubmit={() => {}}
          >
            <Field
              component={FilterBenutzerSelect}
              name={"mitarbeiterId"}
              label={"Mitarbeiter"}
              setBenutzer={setMitarbeiterId}
            ></Field>
          </Formik>
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-urlaub");
            }}
          >
            Urlaub anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function UrlaubResults({ ...request }: DataRequestState<AnwesenheitenFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<AnwesenheitenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAbwesenheiten: DataLoader<AnwesenheitenFilter, AbwesenheitInfo> =
    useCallback(
      async (params) =>
        (await getApi()).abwesenheiten.sucheUrlaub({ ...params }),
      [getApi]
    );
  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["abwesenheiten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAbwesenheiten}
      onRowClick={(row) => {
        navigate("/urlaub/" + row.id);
      }}
      name={"urlaub"}
    />
  );
}
function useColumns(): Array<GridColDef<AbwesenheitInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
      },
      {
        field: "start",
        headerName: "Start",
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString();
          }
          return "";
        },
      },
      {
        field: "ende",
        headerName: "Ende",
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString();
          }
          return "";
        },
      },
      {
        field: "tage",
        headerName: "Tage",
        align: "right",
        headerAlign: "right",
      },
      {
        field: "erstelltAm",
        headerName: "erstellt am",
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString();
          }
          return "";
        },
      },
      {
        field: "bemerkung",
        headerName: "Bemerkung",
      },
    ],
    [t]
  );
}
