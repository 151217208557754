import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useCreateAbwesenheit } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AbwesenheitDaten, Benutzer } from "../../api";
import AbwesenheitenFieldsContent from "../../components/abwesenheiten/AbwesenheitenFieldsContent";

export default function ErstelleUrlaub() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveAbwesenheit();
  let schema = yup.object().shape({
    start: yup.string(),
    ende: yup.string(),
    mitarbeiter: yup.object(),
    bemerkung: yup.string(),
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: {
        start: string;
        ende: string;
        mitarbeiter: any;
        bemerkung: string;
      },
      formikHelpers: FormikHelpers<{
        start: string;
        ende: string;
        mitarbeiter: any;
        bemerkung: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      if (values.start !== "" && values.ende !== "") {
        const mitarbeiterId =
          typeof values.mitarbeiter === "string"
            ? values.mitarbeiter
            : (values.mitarbeiter as Benutzer).id ?? "";
        if (mitarbeiterId !== "") {
          const anwesenheit = await mutateAsync({
            mitarbeiterId: mitarbeiterId,
            start: values.start,
            ende: values.ende,
            type: "Urlaub",
            bemerkung: values.bemerkung,
          });
          navigate(`/urlaub`);
        }
      } else {
        console.log("start", values.start);
        console.log("ende", values.ende);
      }
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Urlaub anlegen"} back="/urlaub">
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            start: "",
            ende: "",
            mitarbeiter: "",
            bemerkung: "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => (
            <>
              <Typography variant="h5">Urlaub anlegen</Typography>
              <DialogContent>
                <Form id="new-urlaub">
                  <AbwesenheitenFieldsContent isVerwaltung={true} />
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="new-urlaub"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}
function useSaveAbwesenheit() {
  const createAbwesenheit = useCreateAbwesenheit();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AbwesenheitDaten) => {
      return createAbwesenheit(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["abwesenheiten"]);
      },
    }
  );
}
