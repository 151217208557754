import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { capitalize, Paper, Typography } from "@mui/material";
import MyUrlaubDataTable from "../../components/urlaub/MyUrlaubDataTable";

export default function MeinUrlaub() {
  const { t } = useT("taetigkeitsbericht");
  const limit = parseInt(localStorage.getItem("meinUrlaubLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">Mein Urlaub</Typography>
        <MyUrlaubDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
