import { capitalize, Paper, Typography } from "@mui/material";
import Layout from "../../../components/Layout";
import FahrzeugDataTable from "../../../components/fuhrpark/fahrzeug/FahrzeugDataTable";
import { useT } from "../../../i18n";

export default function Fahrzeuge() {
  const { t } = useT("fuhrpark");
  const limit = parseInt(localStorage.getItem("fahrzeugeLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">{capitalize(t("vehicles"))}</Typography>
        <FahrzeugDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
