import {
  Box,
  Button,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import {
  Anwesenheit,
  AnwesenheitInfo,
  Entsorger,
  EntsorgerInfo,
} from "../../api";
import CheckIcon from "@mui/icons-material/Check";

export type AnwesenheitenFilter = {
  search?: string;
};

export type Props = Omit<DataRequest<AnwesenheitenFilter>, "filter"> &
  Partial<Pick<DataRequest<AnwesenheitenFilter>, "filter">>;

export default function MyAnwesenheitenDataTable({ ...input }: Props) {
  const request = useDataRequest<AnwesenheitenFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <MyAnwesenheitenResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<AnwesenheitenFilter>) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-meine-anwesenheit");
            }}
          >
            Anwesenheit anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-meine-anwesenheit");
            }}
          >
            Anwesenheit anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function MyAnwesenheitenResults({
  ...request
}: DataRequestState<AnwesenheitenFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<AnwesenheitenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAnwesenheiten: DataLoader<AnwesenheitenFilter, AnwesenheitInfo> =
    useCallback(
      async (params) =>
        (await getApi()).anwesenheiten.sucheMeineAnwesenheiten({ ...params }),
      [getApi]
    );
  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["anwesenheiten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAnwesenheiten}
      onRowClick={(row) => {
        navigate("/meine-anwesenheiten/" + row.id);
      }}
      name={"meine-anwesenheiten"}
    />
  );
}
function useColumns(): Array<GridColDef<AnwesenheitInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "datum",
        headerName: t("date"),
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString();
          }
          return "";
        },
      },
      {
        field: "start",
        headerName: "Start",
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "ende",
        headerName: "Ende",
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "arbeitszeitNetto",
        headerName: "Netto Arbeitszeit",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "fahrzeiten",
        headerName: "Fahrzeiten",
        flex: 1,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "bezahltePause",
        headerName: "bezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "unbezahltePause",
        headerName: "Unbezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
      },
      {
        field: "arbeitszeitBrutto",
        headerName: "Brutto Arbeitszeit",
        flex: 1,
        align: "right",
        headerAlign: "right",
      },
    ],
    [t]
  );
}
