import { Paper, Typography } from "@mui/material";
import VorgaengeDataTable from "../../components/vorgaenge/VorgaengeDataTable";
import { useParams } from "react-router";
import { useBaustelle } from "../../client/queries";

export default function DetailsBaustellenVorgaenge() {
  const { id } = useParams<{ id: string }>();
  const baustelle = useBaustelle(id ?? "");
  const limit = parseInt(
    localStorage.getItem("umsaetze-" + baustelle.id + "Limit") ?? "25",
    10
  );
  return (
    <Paper
      elevation={1}
      sx={{
        marginTop: 2,
        padding: 3,
        marginLeft: 2,
        marginRight: 2,
        position: "relative",
      }}
    >
      <Typography variant={"h5"}>{"Vorgänge"}</Typography>
      <Typography sx={{ mt: 2, mb: 1 }} variant={"h6"}>
        {"Baustelle " + baustelle.nummer + " " + baustelle.name}
      </Typography>
      <VorgaengeDataTable
        baustellenId={baustelle.id}
        limit={limit}
      ></VorgaengeDataTable>
    </Paper>
  );
}
