import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  FieldSort,
  useDataRequest,
  useDebouncedFilter,
} from "../../data";
import { useT } from "../../../i18n";
import DataTable from "../../data/DataTable";
import { useGetApi } from "../../../client";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import WeiseAnbauteilFahrzeugZu from "../../../pages/fuhrpark/fahrzeug/WeiseAnbauteilFahrzeugZu";
import EntferneAnbauteilFahrzeugZuweisung from "../../../pages/fuhrpark/fahrzeug/EntferneAnbauteilFahrzeugZuweisung";
import ZugewieseneAnbauteilKostenDataTable from "./ZugewieseneAnbauteilKostenDataTable";
import NeuerKostensatzAnbauteilZuFahrzeug from "../../../pages/fuhrpark/fahrzeug/NeuerKostensatzAnbauteilZuFahrzeug";
import DeleteZugewiesenesAnbauteilButton from "./DeleteZugewiesenesAnbauteilButton";

export type ZugewiesenesAnbauteilFilter = {
  fahrzeugId: string;
  search?: string;
};

export type ZugewiesenesAnbauteil = {
  id: string;
  anbauteilId: string;
  kostenStunde: number;
};

export type Props = Omit<DataRequest<ZugewiesenesAnbauteilFilter>, "filter"> &
  Partial<Pick<DataRequest<ZugewiesenesAnbauteilFilter>, "filter">>;

export default function ZugewieseneAnbauteileDataTable({ ...input }: Props) {
  const { id: fahrzeugId = "" } = useParams<{
    id: string;
  }>();
  const request = useDataRequest<ZugewiesenesAnbauteilFilter>({
    filter: { fahrzeugId: fahrzeugId },
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <AnbauteilResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<ZugewiesenesAnbauteilFilter>) {
  const [open, setOpen] = useState(false);
  const { t } = useT("fuhrpark");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return <></>;
}

function AnbauteilResults({
  ...request
}: DataRequestState<ZugewiesenesAnbauteilFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<ZugewiesenesAnbauteilFilter>;
}) {
  const [open, setOpen] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [openAssignedCosts, setOpenAssignedCosts] = useState(false);
  const [zugewiesenesAnbauteilId, setZugewiesenesAnbauteilId] =
    useState<string>("");
  const [anbauteilId, setAnbauteilId] = useState<string>("");
  const navigate = useNavigate();
  const getApi = useGetApi();
  const { t } = useT("fuhrpark");

  const handleClickOpenAssign = (
    zugewiesenesAnbauteilId: GridRowId,
    anbauteilRowId: GridRowId
  ) => {
    setOpenAssign(true);
    setZugewiesenesAnbauteilId(zugewiesenesAnbauteilId.toString());
    setAnbauteilId(anbauteilRowId.toString());
  };

  const handleClickOpen = (
    zugewiesenesAnbauteilId: GridRowId,
    anbauteilRowId: GridRowId
  ) => {
    setOpen(true);
    setZugewiesenesAnbauteilId(zugewiesenesAnbauteilId.toString());
    setAnbauteilId(anbauteilRowId.toString());
  };

  const handleClickOpenAssignedCosts = (
    zugewiesenesAnbauteilId: GridRowId,
    anbauteilRowId: GridRowId
  ) => {
    setOpenAssignedCosts(true);
    setZugewiesenesAnbauteilId(zugewiesenesAnbauteilId.toString());
    setAnbauteilId(anbauteilRowId.toString());
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAssign(false);
    setOpenAssignedCosts(false);
  };

  const ladeZugewieseneAnbauteile: DataLoader<
    ZugewiesenesAnbauteilFilter,
    ZugewiesenesAnbauteil
  > = useCallback(
    async (params) =>
      (await getApi()).fahrzeuge.sucheZugewieseneFahrzeugAnbauteile({
        ...params,
      }),
    [getApi]
  );

  const defaultSortKostenHistorie: FieldSort[] = [
    { field: "gueltigVon", direction: "desc" },
  ];

  return (
    <>
      <DataTable
        columns={useColumns(
          handleClickOpen,
          handleClickOpenAssign,
          handleClickOpenAssignedCosts
        )}
        request={request}
        queryKey={["anbauteil-fahrzeug"]}
        loadData={ladeZugewieseneAnbauteile}
      />
      <Dialog
        title={t("edit-attachment-part-assigned")}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <NeuerKostensatzAnbauteilZuFahrzeug
            zugewiesenesAnbauteilId={zugewiesenesAnbauteilId}
            anbauteilId={anbauteilId}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        title={t("remove-attachment-part-assigned")}
        open={openAssign}
        onClose={handleClose}
      >
        <DialogContent>
          <EntferneAnbauteilFahrzeugZuweisung
            zugewiesenesAnbauteilId={zugewiesenesAnbauteilId}
            anbauteilId={anbauteilId}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        title={t("assigned-costs-header")}
        open={openAssignedCosts}
        onClose={handleClose}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <DialogContent>
          <ZugewieseneAnbauteilKostenDataTable
            zugewiesenesAnbauteilId={zugewiesenesAnbauteilId}
            anbauteilId={anbauteilId}
            sort={defaultSortKostenHistorie}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
function useColumns(
  handleDialogOpen: (
    zugewiesenesAnbauteilId: GridRowId,
    anbauteilRowId: GridRowId
  ) => void,
  handleAssignDialogOpen: (
    zugewiesenesAnbauteilId: GridRowId,
    anbauteilRowId: GridRowId
  ) => void,
  handleAssignedCostsDialogOpen: (
    zugewiesenesAnbauteilId: GridRowId,
    anbauteilRowId: GridRowId
  ) => void
): Array<GridColDef<ZugewiesenesAnbauteil>> {
  const { t, capitalize } = useT("fuhrpark");

  return useMemo(
    () => [
      {
        field: "geraeteBezeichnung",
        headerName: t("attachment-part-title"),
        flex: 1,
      },
      {
        field: "kostenStunde",
        headerName: t("assign-attachment-part-cost-hour"),
        flex: 1,
      },
      {
        field: "gueltigVon",
        headerName: t("assign-attachment-part-valid-from"),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleString();
          }
          return "";
        },
      },
      {
        field: "editAssignedAnbauteil",
        headerName: "",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 60,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={() =>
                handleDialogOpen(params.row.id, params.row.anbauteilId)
              }
            >
              <EditIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: "infoAssignedAnbauteilKosten",
        headerName: "",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 60,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={() =>
                handleAssignedCostsDialogOpen(
                  params.row.id,
                  params.row.anbauteilId
                )
              }
            >
              <HelpIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: "removeAssignmentAnbauteil",
        headerName: "",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 60,
        renderCell: (params) => (
          <>
            <DeleteZugewiesenesAnbauteilButton id={params.row.id} />
          </>
        ),
      },
    ],
    [t, capitalize]
  );
}
