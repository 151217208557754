import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { capitalize, Paper, Typography } from "@mui/material";
import MyUrlaubDataTable from "../../components/urlaub/MyUrlaubDataTable";
import UrlaubDataTable from "../../components/urlaub/UrlaubDataTable";

export default function Urlaub() {
  const { t } = useT("taetigkeitsbericht");
  const limit = parseInt(localStorage.getItem("urlaubLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">Urlaub</Typography>
        <UrlaubDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
