/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Taetigkeitsbericht } from '../models/Taetigkeitsbericht';
import type { TaetigkeitsberichtDaten } from '../models/TaetigkeitsberichtDaten';
import type { TaetigkeitsberichteExternPage } from '../models/TaetigkeitsberichteExternPage';
import type { TaetigkeitsberichteInternPage } from '../models/TaetigkeitsberichteInternPage';
import type { TaetigkeitsberichtePage } from '../models/TaetigkeitsberichtePage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaetigkeitsberichteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Taetigkeitsberichte.
   * @returns TaetigkeitsberichtePage Die Seite der Taetigkeitsberichte.
   * @throws ApiError
   */
  public sucheTaetigkeitsberichte({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<TaetigkeitsberichtePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Taetigkeitsbericht der angelegte Taetigkeitsbericht
   * @throws ApiError
   */
  public createTaetigkeitsbericht({
    requestBody,
  }: {
    requestBody: TaetigkeitsberichtDaten,
  }): CancelablePromise<Taetigkeitsbericht> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle internen Taetigkeitsberichte.
   * @returns TaetigkeitsberichteInternPage Die Seite der internen Taetigkeitsberichte.
   * @throws ApiError
   */
  public sucheInterneTaetigkeitsberichte({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<TaetigkeitsberichteInternPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/intern',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle externen Taetigkeitsberichte.
   * @returns TaetigkeitsberichteExternPage Die Seite der externen Taetigkeitsberichte.
   * @throws ApiError
   */
  public sucheExterneTaetigkeitsberichte({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<TaetigkeitsberichteExternPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/extern',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme ein bestimmten Taetigkeitsbericht.
   * @returns Taetigkeitsbericht Der Taetigkeitsbericht.
   * @throws ApiError
   */
  public getTaetigkeitsbericht({
    taetigkeitsberichtId,
  }: {
    taetigkeitsberichtId: string,
  }): CancelablePromise<Taetigkeitsbericht> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Taetigkeitsbericht der aktualisierte Taetigkeitsbericht
   * @throws ApiError
   */
  public updateTaetigkeitsbericht({
    taetigkeitsberichtId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    requestBody: TaetigkeitsberichtDaten,
  }): CancelablePromise<Taetigkeitsbericht> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Taetigkeitsbericht der gelöschte Taetigkeitsbericht
   * @throws ApiError
   */
  public deleteTaetigkeitsbericht({
    taetigkeitsberichtId,
  }: {
    taetigkeitsberichtId: string,
  }): CancelablePromise<Taetigkeitsbericht> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
