import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useGetBenutzer, useUpdateAnwesenheit } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AnwesenheitDaten, Benutzer } from "../../api";
import AnwesenheitFieldsContent from "../../components/anwesenheiten/AnwesenheitFieldsContent";
import { useAnwesenheit, useBenutzer } from "../../client/queries";
import DeleteAnwesenheitButton from "../../components/anwesenheiten/DeleteAnwesenheitButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DetailsAnwesenheit() {
  const { t } = useTranslation("customer");
  const { id: anwesenheitId = "" } = useParams<{
    id: string;
  }>();
  const anwesenheit = useAnwesenheit(anwesenheitId);
  const benutzer = useBenutzer(anwesenheit.mitarbeiterId);
  const { mutateAsync } = useSaveAnwesenheit(anwesenheitId);
  let schema = yup.object().shape({
    datum: yup.string().required(),
    start: yup.string(),
    ende: yup.string(),
    bezahltePause: yup.number(),
    unbezahltePause: yup.number(),
    fahrzeiten: yup.number(),
    arbeitszeitBrutto: yup.number(),
    arbeitszeitNetto: yup.number(),
    mitarbeiter: yup.object(),
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: {
        datum: string;
        start: string;
        ende: string;
        mitarbeiter: any;
        bezahltePause: number;
        unbezahltePause: number;
        fahrzeiten: number;
        arbeitszeitBrutto: number;
        arbeitszeitNetto: number;
      },
      formikHelpers: FormikHelpers<{
        datum: string;
        start: string;
        ende: string;
        mitarbeiter: any;
        bezahltePause: number;
        unbezahltePause: number;
        fahrzeiten: number;
        arbeitszeitBrutto: number;
        arbeitszeitNetto: number;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      if (values.start !== "" && values.ende !== "") {
        const mitarbeiterId =
          typeof values.mitarbeiter === "string"
            ? values.mitarbeiter
            : (values.mitarbeiter as Benutzer).id ?? "";
        if (mitarbeiterId !== "") {
          const anwesenheit = await mutateAsync({
            datum: values.datum,
            mitarbeiterId: mitarbeiterId,
            start: values.start,
            ende: values.ende,
            bezahltePause: values.bezahltePause,
            unbezahltePause: values.unbezahltePause,
            fahrzeiten: values.fahrzeiten,
            arbeitszeitBrutto: values.arbeitszeitBrutto,
            arbeitszeitNetto: values.arbeitszeitNetto,
          });
          navigate(`/anwesenheiten`);
        }
      } else {
        console.log("start", values.start);
        console.log("ende", values.ende);
      }
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <Layout title={"Anwesenheit"} back={"/anwesenheiten"}>
      <Paper
        elevation={1}
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
          position: "relative",
        }}
      >
        <DeleteAnwesenheitButton id={anwesenheitId}></DeleteAnwesenheitButton>
        <Formik
          initialValues={{
            datum: anwesenheit.datum ?? getCurrentDate(),
            start: anwesenheit.start,
            ende: anwesenheit.ende,
            mitarbeiter: {
              id: anwesenheit.mitarbeiterId,
              name: `${benutzer?.name}, ${benutzer?.vorname}`,
            },
            bezahltePause: anwesenheit.bezahltePause ?? 0,
            unbezahltePause: anwesenheit.unbezahltePause ?? 0,
            fahrzeiten: anwesenheit.fahrzeiten ?? 0,
            arbeitszeitBrutto: anwesenheit.arbeitszeitBrutto ?? 0,
            arbeitszeitNetto: anwesenheit.arbeitszeitNetto ?? 0,
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => (
            <>
              <Typography variant="h5">
                {anwesenheit.geloeschtAm === undefined ? (
                  "Anwesenheit"
                ) : (
                  <>
                    <>{"Anwesenheit "}</>
                    <Chip
                      size="small"
                      icon={<DeleteIcon />}
                      label={"gelöscht"}
                      color="error"
                    />
                  </>
                )}
              </Typography>
              <DialogContent>
                <Form id="new-anwesenheit">
                  <AnwesenheitFieldsContent
                    isVerwaltungAnwesenheit={true}
                    startzeit={values.start}
                    endzeit={values.ende}
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="new-anwesenheit"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}
function useSaveAnwesenheit(id: string) {
  const updatedAnwesenheit = useUpdateAnwesenheit();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AnwesenheitDaten) => {
      return updatedAnwesenheit(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["anwesenheiten"]);
      },
    }
  );
}
