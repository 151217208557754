import {
  MaterialKosten,
  MaterialKostenZeitraum,
  MaterialKostenZeitraumPrice,
} from "../../../api";
import { useCallback, useEffect, useState } from "react";
import {
  useGetMaterialCosts,
  useUpdateMaterialPrice,
  useUpdateMaterialZeitraum,
} from "../../../client/hooks";
import { Button, CircularProgress, IconButton, Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient } from "react-query";
import * as yup from "yup";
export default function MaterialKostenEditableRowPrice({
  type,
  price,
  eintrag,
}: {
  type: string;
  price: number;
  eintrag: MaterialKosten;
}) {
  const [editing, setEditing] = useState(false);
  const update = useUpdateMaterialPrice();
  const existingPeriods = useGetMaterialCosts();
  const [periods, setPeriods] = useState<MaterialKosten[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const queryClient = useQueryClient();
  useEffect(() => {
    const fetchPeriods = async () => {
      if (eintrag.materialId) {
        const materialsData = await existingPeriods(eintrag.materialId, {
          search: "",
        });
        const index = materialsData.items.findIndex(
          (item) => item.id === eintrag.id
        );
        setCurrentIndex(index);
        setPeriods(materialsData.items);
      }
    };
    fetchPeriods();
  }, [eintrag.id, eintrag.materialId, existingPeriods]);
  const onSubmit = useCallback(
    async (values: MaterialKostenZeitraumPrice) => {
      if (eintrag.id) {
        await update(eintrag.id, {
          type: type,
          price: values.price,
        });
      }
      await queryClient.invalidateQueries("material-kosten");
      setEditing(false);
    },
    [update, setEditing]
  );

  let schema = yup.object().shape({
    type: yup.string(),
    price: yup.string(),
  });
  return (
    <>
      <Stack direction="row">
        {editing ? (
          <></>
        ) : (
          <>
            <Button
              color="inherit"
              sx={{ fontSize: "1em", fontWeight: "normal" }}
              onClick={() => {
                setEditing(true);
              }}
            >
              {price ?? 0}
            </Button>
          </>
        )}
        {editing ? (
          <Formik
            initialValues={{
              type: type,
              price: price ?? 0,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <Stack direction="row">
                  <Field
                    name="price"
                    component={TextField}
                    type={"number"}
                    variant="standard"
                  />
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton
                      type="submit"
                      size="small"
                      color="success"
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      {isSubmitting ? (
                        <CircularProgress size="1em" />
                      ) : (
                        <CheckIcon />
                      )}
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => setEditing(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
}
