import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import KundenDataTable from "../../components/kunden/KundenDataTable";
import { useT } from "../../i18n";

export default function KundenListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("kundenLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">{t("customers")}</Typography>
        <KundenDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
