import { capitalize, Paper, Typography, SxProps, Theme } from "@mui/material";
import { useT } from "../../../i18n";
import AnbauteilDataTable from "../../../components/fuhrpark/anbauteil/AnbauteilDataTable";

interface AnbauteileProps {
  sx?: SxProps<Theme>;
}

export default function Anbauteile({ sx }: AnbauteileProps) {
  const { t } = useT("fuhrpark");
  const limit = parseInt(localStorage.getItem("anbauteileLimit") ?? "25", 10);
  return (
    <Paper elevation={1} sx={{ padding: 3, ...sx }}>
      <Typography variant="h5">{capitalize(t("attachment-parts"))}</Typography>
      <AnbauteilDataTable limit={limit} />
    </Paper>
  );
}
