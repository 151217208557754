import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import AbfallartenDataTable from "../../components/abfallarten/AbfallartenDataTable";

export default function AbfallartenListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("abfallartenLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">Abfallarten</Typography>
        <AbfallartenDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
