import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useT } from "../../../i18n";
import { useDeleteFuhrparkeintrag } from "../../../client/hooks";

export default function DeleteFuhrparkeintragButton({
  remove,
  index,
  id,
  taetigkeitsberichtId,
}: {
  remove: (value: any) => void;
  index: number;
  id: string;
  taetigkeitsberichtId: string;
}) {
  const { t } = useT("taetigkeitsbericht");
  const mutate = useLoescheFuhrparkeintrag(id, taetigkeitsberichtId);
  const popupStateFuhrparkeintragDelete = usePopupState({
    variant: "popover",
    popupId: `fuhrparkeintrag-${id}-delete`,
  });
  return (
    <>
      <IconButton
        {...bindTrigger(popupStateFuhrparkeintragDelete)}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      <Dialog {...bindPopover(popupStateFuhrparkeintragDelete)}>
        <DialogTitle id="alert-dialog-title-fuhrparkeintrag">
          {capitalize(t("sure-delete-carpool-entry-header"))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-fuhrparkeintrag">
            {capitalize(t("sure-delete-carpool-entry-body"))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => popupStateFuhrparkeintragDelete.close()}
            color="inherit"
          >
            {capitalize(t("abort"))}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (id) {
                mutate.mutate();
                remove(index);
                popupStateFuhrparkeintragDelete.close();
              } else {
                remove(index);
              }
            }}
          >
            {capitalize(t("delete"))}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
function useLoescheFuhrparkeintrag(id: string, taetigkeitsberichtId: string) {
  const deleteFuhrparkeintrag = useDeleteFuhrparkeintrag();
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return deleteFuhrparkeintrag(taetigkeitsberichtId, id);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["fuhrparkeintraege"]);
      },
    }
  );
}
