import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import EntsorgerDataTable from "../../components/entsorger/EntsorgerDataTable";

export default function EntsorgerListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("entsorgerLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">Entsorger</Typography>
        <EntsorgerDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
