import { Box, Stack } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import BenutzerSelect from "../benutzer/BenutzerSelect";

export default function AbwesenheitenFieldsContent({
  isVerwaltung,
}: {
  isVerwaltung: boolean;
}) {
  const { t } = useT("entsorger");

  return (
    <Stack direction="column">
      {isVerwaltung ? (
        <Box
          display={"grid"}
          sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
        >
          <Field
            component={BenutzerSelect}
            label={"Mitarbeiter *"}
            name="mitarbeiter"
          />
        </Box>
      ) : (
        <></>
      )}
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"Start *"}
          name="start"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <Field
          component={TextField}
          label={"Ende *"}
          name="ende"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field component={TextField} label={"Bemerkung"} name="bemerkung" />
      </Box>
    </Stack>
  );
}
