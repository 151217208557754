import {
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GridColDef, GridRowId, GridSortModel } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  FieldSort,
  SortDirection,
  useDataRequest,
  useDebouncedFilter,
} from "../../data";
import { useT } from "../../../i18n";
import DataTable from "../../data/DataTable";
import { useGetApi } from "../../../client";
import { Sort } from "@mui/icons-material";

export type ZugewieseneAnbauteilKostenFilter = {
  fahrzeugId: string;
  anbauteilId: string;
  search?: string;
};

export type ZugewieseneAnbauteilKosten = {
  id: string;
  kostenVersion: number;
  kostenStunde: number;
};

export type Props = Omit<
  DataRequest<ZugewieseneAnbauteilKostenFilter>,
  "filter"
> &
  Partial<Pick<DataRequest<ZugewieseneAnbauteilKostenFilter>, "filter">> & {
    zugewiesenesAnbauteilId: string;
    anbauteilId: string;
  };

export default function ZugewieseneAnbauteilKostenDataTable({
  anbauteilId,
  zugewiesenesAnbauteilId,
  ...input
}: Props) {
  const { id: fahrzeugId = "" } = useParams<{
    id: string;
  }>();
  const { t } = useT("fuhrpark");
  const request = useDataRequest<ZugewieseneAnbauteilKostenFilter>({
    filter: { fahrzeugId: fahrzeugId, anbauteilId: anbauteilId },
    ...input,
  });

  return (
    <>
      <Paper elevation={1} sx={{ padding: 3 }}>
        <Stack direction={"column"} spacing={2}>
          <Typography variant="h5">{t("cost-history")}</Typography>
          <FilterComp {...request} />
          <AnbauteilResults {...request} />
        </Stack>
      </Paper>
    </>
  );
}

function FilterComp(
  request: DataRequestState<ZugewieseneAnbauteilKostenFilter>
) {
  const [open, setOpen] = useState(false);
  const { t } = useT("fuhrpark");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return <></>;
}

function AnbauteilResults({
  ...request
}: DataRequestState<ZugewieseneAnbauteilKostenFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<ZugewieseneAnbauteilKostenFilter>;
}) {
  const [open, setOpen] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [openAssignedCosts, setOpenAssignedCosts] = useState(false);
  const [anbauteilId, setAnbauteilId] = useState<string>("");
  const navigate = useNavigate();
  const getApi = useGetApi();
  const { t } = useT("fuhrpark");

  const handleClickOpenAssign = (anbauteilRowId: GridRowId) => {
    setOpenAssign(true);
    setAnbauteilId(anbauteilRowId.toString());
  };

  const handleClickOpen = (anbauteilRowId: GridRowId) => {
    setOpen(true);
    setAnbauteilId(anbauteilRowId.toString());
  };

  const handleClickOpenAssignedCosts = (anbauteilRowId: GridRowId) => {
    setOpenAssignedCosts(true);
    setAnbauteilId(anbauteilRowId.toString());
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAssign(false);
    setOpenAssignedCosts(false);
  };

  const ladeZugewieseneAnbauteilKosten: DataLoader<
    ZugewieseneAnbauteilKostenFilter,
    ZugewieseneAnbauteilKosten
  > = useCallback(
    async (params) =>
      (await getApi()).fahrzeuge.getFahrzeugAnbauteilKosten({ ...params }),
    [getApi]
  );

  return (
    <>
      <DataTable
        columns={useColumns(
          handleClickOpen,
          handleClickOpenAssign,
          handleClickOpenAssignedCosts
        )}
        request={request}
        queryKey={["anbauteil-fahrzeug"]}
        loadData={ladeZugewieseneAnbauteilKosten}
        name={"anbauteil-fahrzeug"}
      />
    </>
  );
}
function useColumns(
  handleDialogOpen: (anbauteilRowId: GridRowId) => void,
  handleAssignDialogOpen: (anbauteilRowId: GridRowId) => void,
  handleAssignedCostsDialogOpen: (anbauteilRowId: GridRowId) => void
): Array<GridColDef<ZugewieseneAnbauteilKosten>> {
  const { t, capitalize } = useT("fuhrpark");

  return useMemo(
    () => [
      {
        field: "gueltigVon",
        headerName: t("assign-attachment-part-valid-from"),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleString();
          }
          return "";
        },
      },
      {
        field: "gueltigBis",
        headerName: t("assign-attachment-part-valid-to"),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleString();
          }
          return "aktuell";
        },
      },
      {
        field: "kostenStunde",
        headerName: t("assign-attachment-part-cost-hour"),
        flex: 1,
      },
    ],
    [t, capitalize]
  );
}
