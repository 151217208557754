import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Erzeuger, ErzeugerListe } from "../../api";
import { useGetErzeuger, useSucheErzeuger } from "../../client/hooks";

interface ErzeugerSelectProps extends FieldProps {
  label: string;
  required?: boolean;
}

function ErzeugerSelect({
  label,
  required,
  field,
  form,
  meta,
}: ErzeugerSelectProps) {
  const searchErzeuger = useSucheErzeuger();
  const ladeErzeuger = useGetErzeuger();
  const [optionen, setOptionen] = useState<ReadonlyArray<Erzeuger>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeErzeuger(field.value)
        .then((erzeuger) => {
          form.setFieldValue(field.name, erzeuger);
          setInputValue(erzeuger);
        })
        .catch((error) => {
          console.error("Error loading erzeuger:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: ErzeugerListe) => void
        ) =>
          searchErzeuger({
            search: request.input,
            page: 0,
            limit: 20,
            sort: "nummer",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchErzeuger]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Erzeuger) => {
        if (typeof option === "string") {
          return "";
        } else {
          return option.nummer;
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Erzeuger) => {
        return (
          <ListItem {...props}>
            <ListItemText primary={option.nummer} />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default ErzeugerSelect;
