import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Baustelle, BaustellenListe } from "../../api";
import {
  useGetBaustelle,
  useSucheBaustellen,
  useGetKunde,
} from "../../client/hooks";

interface BaustellenSelectProps extends FieldProps {
  label: string;
  required?: boolean;
}

function BaustellenSelect({
  label,
  required,
  field,
  form,
  meta,
}: BaustellenSelectProps) {
  const searchBaustellen = useSucheBaustellen();
  const ladeBaustelle = useGetBaustelle();
  const ladeKunde = useGetKunde();
  const [optionen, setOptionen] = useState<ReadonlyArray<Baustelle>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");
  const [kundenNamen, setKundenNamen] = useState<Record<string, string>>({});

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeBaustelle(field.value)
        .then((baustelle) => {
          form.setFieldValue(field.name, baustelle);
          setInputValue(baustelle);
        })
        .catch((error) => {
          console.error("Error loading user:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: BaustellenListe) => void
        ) =>
          searchBaustellen({
            search: request.input,
            page: 0,
            limit: 20,
            sort: "name",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchBaustellen]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
      if (results) {
        results.items.forEach((item: Baustelle) => {
          if (item.kundenId) {
            const kundenId: string = item.kundenId;
            if (!kundenNamen[kundenId]) {
              ladeKunde(kundenId).then((kunde) => {
                setKundenNamen((prev) => ({
                  ...prev,
                  [kundenId]: kunde.name,
                }));
              });
            }
          }
        });
      }
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Baustelle) => {
        if (typeof option === "string") {
          return "";
        } else {
          return option.name;
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Baustelle) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={option.name}
              secondary={[
                option.nummer,
                kundenNamen[option.kundenId ?? ""] ?? option.kundenId ?? null,
              ]
                .filter(Boolean)
                .join(", ")}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default BaustellenSelect;
