import {
  Box,
  Button,
  capitalize,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FastField, FieldArray, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../i18n";
import FahrzeugSelect from "../../fuhrpark/fahrzeug/FahrzeugSelect";
import React, { useEffect, useState } from "react";
import {
  ActionTimestamp,
  Buchungstyp,
  EinheitenListe,
  Fahrzeug,
  HerkunftTyp,
} from "../../../api";
import {
  useGetFahrzeug,
  useGetFahrzeugKostenFuerDatum,
} from "../../../client/hooks";
import AddIcon from "@mui/icons-material/Add";
import FuhrparkeintragAnbauteileFieldsContent from "./fuhrparkeintraganbauteile/FuhrparkeintragAnbauteileFieldsContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MaschinentransportFieldsContent from "./maschinentransporte/MaschinentransporteFieldsContent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FoundationIcon from "@mui/icons-material/Foundation";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CategoryIcon from "@mui/icons-material/Category";
import MaterialanfuhrFieldsContent from "./materialanfuhr/MaterialanfuhrFieldsContent";
import MaterialabtransportFieldsContent from "./materialabtransport/MaterialabtransportFieldsContent";
import MaterialtransportFieldsContent from "./materialtransport/MaterialtransportFieldsContent";
import { useTranslation } from "react-i18next";

interface MaschinentransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  baustelleId: string;
  transportiertesFahrzeug: any;
  fuhrTyp: Buchungstyp;
  bemerkung?: string;
  lieferscheinNummer?: string;
  abholortBaustelle?: any;
  abstellortBaustelle?: any;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: string;
  abfallart?: string;
}

interface ExtendedFuhrparkeintragAnbauteilDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  anbauteil: any;
  anbauteilNutzungsdauer: number;
  anbauteilAnzahl: number;
  anbauteilStundenPreis: number;
  anbauteilKosten: number;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiterId: string;
  baustelleId: string;
  datum: string;
  arbeitszeitNetto: number;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugHerkunft: string;
  fahrzeug: any;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: ExtendedFuhrparkeintragAnbauteilDaten[];
  maschinentransporte: MaschinentransportDaten[];
  materialanfuhr: MaterialTransportDaten[];
  materialabtransport: MaterialTransportDaten[];
  materialtransport: MaterialTransportDaten[];
}

export default function FuhrparkeintragFieldsContent({
  isNew,
  index,
  fahrzeugHerkunft,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isValidating,
  isValid,
  isFremdleistung,
  fuhrparkeintrag,
}: {
  isNew: boolean;
  index: number;
  fahrzeugHerkunft: HerkunftTyp;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isValidating: boolean;
  isValid: boolean;
  isFremdleistung: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
}) {
  const { t } = useT("taetigkeitsbericht");

  const { setFieldValue } = useFormikContext();

  const [anchorElFahrzeuganlegenInfo, setAnchorElFahrzeuganlegenInfo] =
    React.useState<HTMLElement | null>(null);
  const handlePopoverOpenFahrzeuganlegenInfo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElFahrzeuganlegenInfo(event.currentTarget);
  };

  const handlePopoverCloseFahrzeuganlegenInfo = () => {
    setAnchorElFahrzeuganlegenInfo(null);
  };

  const openFahrzeuganlegenInfo = Boolean(anchorElFahrzeuganlegenInfo);

  const bekommeFahrzeugKosten = useGetFahrzeugKostenFuerDatum();

  const [ueberstiegeneNutzungsdauer, setUeberstiegeneNutzungsdauer] =
    useState<boolean>(false);

  const [fahrzeugField] = useField(`fuhrparkeintraege.${index}.fahrzeug`);
  const [fahrzeugNutzungsdauerField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`
  );
  const [fahrzeugStundenpreisField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugStundenPreis`
  );

  const calculateFahrzeugkosten = () => {
    setFieldValue(
      `fuhrparkeintraege.${index}.fahrzeugKosten`,
      (
        fahrzeugNutzungsdauerField.value * fahrzeugStundenpreisField.value
      ).toFixed(2)
    );
  };

  const calculateGesamtKosten = () => {
    var anbauteilGesamt = 0;
    fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil) => {
        anbauteilGesamt += Number(fuhrparkeintragAnbauteil.anbauteilKosten);
      }
    );

    var fahrzeugGesamt =
      fahrzeugNutzungsdauerField.value * fahrzeugStundenpreisField.value;
    setFieldValue(
      `fuhrparkeintraege.${index}.anbauteilGesamtKosten`,
      anbauteilGesamt.toFixed(2)
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.fuhrparkGesamtKosten`,
      (fahrzeugGesamt + anbauteilGesamt).toFixed(2)
    );
  };

  useEffect(() => {
    calculateFahrzeugkosten();
    calculateGesamtKosten();
  }, [
    fahrzeugNutzungsdauerField.value,
    fahrzeugStundenpreisField.value,
    fuhrparkeintrag.fuhrparkeintragAnbauteile,
  ]);

  useEffect(() => {
    var gesamtAnbauteileNutzungsdauer = 0;
    fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil) => {
        gesamtAnbauteileNutzungsdauer +=
          fuhrparkeintragAnbauteil.anbauteilNutzungsdauer;
      }
    );
    setUeberstiegeneNutzungsdauer(
      fahrzeugNutzungsdauerField.value < gesamtAnbauteileNutzungsdauer
    );
  }, [
    fahrzeugNutzungsdauerField.value,
    fuhrparkeintrag.fuhrparkeintragAnbauteile,
  ]);

  useEffect(() => {
    const fetchAndSetFahrzeugStundenPreis = async () => {
      const fahrzeug = fahrzeugField.value;
      const fahrzeugIdWert =
        typeof fahrzeug === "string" ? fahrzeug : (fahrzeug as Fahrzeug)?.id;
      if (fahrzeugIdWert && isNew) {
        try {
          if (taetigkeitsberichtDatum) {
            taetigkeitsberichtDatum = new Date(
              taetigkeitsberichtDatum
            ).toISOString();
          } else {
            taetigkeitsberichtDatum = new Date().toISOString();
          }

          const fahrzeugStundenPreis = await bekommeFahrzeugKosten(
            fahrzeugIdWert,
            { date: taetigkeitsberichtDatum }
          );

          setFieldValue(
            `fuhrparkeintraege.${index}.fahrzeugStundenPreis`,
            fahrzeugStundenPreis.kostenStunde.toFixed(2)
          );
        } catch (error) {
          setFieldValue(`fuhrparkeintraege.${index}.fahrzeugStundenPreis`, 0);
        }
      }
    };

    fetchAndSetFahrzeugStundenPreis();

    if (!fahrzeugField.value && isNew && dauerVorhanden) {
      setFieldValue(
        `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`,
        dauerVorhanden
      );
    }
  }, [fahrzeugField.value, index, setFieldValue]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 5.5fr .5fr 1fr 2fr 2fr 2fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-duration"))}
          name={`fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          required
        />
        <FastField
          component={FahrzeugSelect}
          name={`fuhrparkeintraege.${index}.fahrzeug`}
          label={capitalize(t("carpool-entry-vehicle"))}
          required
          fahrzeugHerkunft={fahrzeugHerkunft}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => window.open("/neues-fahrzeug")}
          aria-owns={openFahrzeuganlegenInfo ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpenFahrzeuganlegenInfo}
          onMouseLeave={handlePopoverCloseFahrzeuganlegenInfo}
        >
          <AddIcon />
        </Button>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={openFahrzeuganlegenInfo}
          anchorEl={anchorElFahrzeuganlegenInfo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverCloseFahrzeuganlegenInfo}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {capitalize(t("create-vehicle-info"))}
          </Typography>
        </Popover>
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-price"))}
          name={`fuhrparkeintraege.${index}.fahrzeugStundenPreis`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-vehicle-costs"))}
          name={`fuhrparkeintraege.${index}.fahrzeugKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-total-costs"))}
          name={`fuhrparkeintraege.${index}.anbauteilGesamtKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-vehicle-total-costs"))}
          name={`fuhrparkeintraege.${index}.fuhrparkGesamtKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
      </Box>

      {ueberstiegeneNutzungsdauer ? (
        <Box>
          <Typography color={"#cc6600"} sx={{ fontSize: "10pt" }}>
            {capitalize(t("warning-attachment-usage-over-carpool"))}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <FieldArray name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.fuhrparkeintragAnbauteile.length === 0 ? (
              <Box
                display={"grid"}
                sx={{
                  gridTemplateColumns: ["1fr", "1fr"],
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    push({
                      anbauteil: "",
                      anbauteilNutzungsdauer: 0,
                      anbauteilAnzahl: 1,
                      anbauteilStundenPreis: 0,
                    });
                  }}
                  disabled={!fuhrparkeintrag.fahrzeug}
                >
                  <Typography sx={{ p: 0.5 }}>
                    {capitalize(t("create-attachment-part-info"))}
                  </Typography>
                  <AddCircleIcon />
                </Button>
              </Box>
            ) : (
              <></>
            )}
            {fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil, indexAnbauteil) => (
                <Box key={indexAnbauteil} sx={{ marginTop: 2 }}>
                  <Box
                    display={"grid"}
                    sx={{
                      gridTemplateColumns: ["1fr", "10fr .1fr"],
                      gap: 2,
                    }}
                  >
                    <Box
                      id={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${indexAnbauteil}`}
                    >
                      <FuhrparkeintragAnbauteileFieldsContent
                        dauerVorhanden={dauerVorhanden}
                        fahrzeugId={
                          typeof fuhrparkeintrag.fahrzeug === "string"
                            ? fuhrparkeintrag.fahrzeug
                            : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id
                        }
                        index={index}
                        anbauteilIndex={indexAnbauteil}
                        isNew={!fuhrparkeintragAnbauteil.id}
                        taetigkeitsberichtDatum={taetigkeitsberichtDatum}
                        push={push}
                        remove={remove}
                        newestIndex={
                          fuhrparkeintrag.fuhrparkeintragAnbauteile.length - 1
                        }
                        fuhrparkeintragAnbauteil={fuhrparkeintragAnbauteil}
                      />
                    </Box>
                  </Box>
                </Box>
              )
            )}
          </>
        )}
      </FieldArray>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-remark"))}
          name={`fuhrparkeintraege.${index}.bemerkung`}
          type={"box"}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr 1fr 8fr"],
          gap: 2,
          marginTop: 4,
        }}
      >
        <NeuerMaterialanfuhrButton
          values={fuhrparkeintrag}
          index={index}
          fahrzeugId={
            typeof fuhrparkeintrag.fahrzeug === "string"
              ? fuhrparkeintrag.fahrzeug
              : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id
          }
        />
        <NeuerMaterialtransportZwischenBaustellenButton
          values={fuhrparkeintrag}
          index={index}
          fahrzeugId={
            typeof fuhrparkeintrag.fahrzeug === "string"
              ? fuhrparkeintrag.fahrzeug
              : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id
          }
        />
        <NeuerMaterialabtransportButton
          values={fuhrparkeintrag}
          index={index}
          fahrzeugId={
            typeof fuhrparkeintrag.fahrzeug === "string"
              ? fuhrparkeintrag.fahrzeug
              : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id
          }
        />
        {!isFremdleistung ? (
          <NeuerMaschinentransportButton
            values={fuhrparkeintrag}
            index={index}
            fahrzeugId={
              typeof fuhrparkeintrag.fahrzeug === "string"
                ? fuhrparkeintrag.fahrzeug
                : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id
            }
          />
        ) : (
          <></>
        )}
      </Box>
      <FieldArray name={`fuhrparkeintraege.${index}.materialtransport`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.materialtransport ? (
              fuhrparkeintrag.materialtransport.map(
                (materialabtransport, indexMaterialabtransport) => (
                  <Box key={indexMaterialabtransport} sx={{ marginTop: 2 }}>
                    <Box
                      display={"grid"}
                      sx={{
                        gridTemplateColumns: ["1fr", "10fr .1fr"],
                        gap: 2,
                      }}
                    >
                      <Box
                        id={`fuhrparkeintraege.${index}.materialtransport.${indexMaterialabtransport}`}
                      >
                        <MaterialtransportFieldsContent
                          index={index}
                          materialTransportIndex={indexMaterialabtransport}
                          push={push}
                          remove={remove}
                          newestIndex={
                            fuhrparkeintrag.materialabtransport.length - 1
                          }
                          materialtransport={materialabtransport}
                          fahrzeugId={
                            typeof fuhrparkeintrag.fahrzeug === "string"
                              ? fuhrparkeintrag.fahrzeug
                              : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? ""
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              )
            ) : (
              <></>
            )}
          </>
        )}
      </FieldArray>
      <FieldArray name={`fuhrparkeintraege.${index}.materialanfuhr`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.materialanfuhr ? (
              fuhrparkeintrag.materialanfuhr.map(
                (materialanfuhr, indexMaterialanfuhr) => (
                  <Box key={indexMaterialanfuhr} sx={{ marginTop: 2 }}>
                    <Box
                      display={"grid"}
                      sx={{
                        gridTemplateColumns: ["1fr", "10fr .1fr"],
                        gap: 2,
                      }}
                    >
                      <Box
                        id={`fuhrparkeintraege.${index}.materialanfuhr.${indexMaterialanfuhr}`}
                      >
                        <MaterialanfuhrFieldsContent
                          index={index}
                          materialTransportIndex={indexMaterialanfuhr}
                          push={push}
                          remove={remove}
                          newestIndex={
                            fuhrparkeintrag.materialanfuhr.length - 1
                          }
                          materialtransport={materialanfuhr}
                          fahrzeugId={
                            typeof fuhrparkeintrag.fahrzeug === "string"
                              ? fuhrparkeintrag.fahrzeug
                              : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? ""
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              )
            ) : (
              <></>
            )}
          </>
        )}
      </FieldArray>

      <FieldArray name={`fuhrparkeintraege.${index}.maschinentransporte`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.maschinentransporte.map(
              (maschinentransport, indexMaschinentransport) => (
                <Box key={indexMaschinentransport} sx={{ marginTop: 2 }}>
                  <Box
                    display={"grid"}
                    sx={{
                      gridTemplateColumns: ["1fr", "10fr .1fr"],
                      gap: 2,
                    }}
                  >
                    <Box
                      id={`fuhrparkeintraege.${index}.maschinentransporte.${indexMaschinentransport}`}
                    >
                      <MaschinentransportFieldsContent
                        index={index}
                        maschinenTransportIndex={indexMaschinentransport}
                        push={push}
                        remove={remove}
                        newestIndex={
                          fuhrparkeintrag.maschinentransporte.length - 1
                        }
                        maschinentransport={maschinentransport}
                      />
                    </Box>
                  </Box>
                </Box>
              )
            )}
          </>
        )}
      </FieldArray>

      <FieldArray name={`fuhrparkeintraege.${index}.materialabtransport`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.materialabtransport ? (
              fuhrparkeintrag.materialabtransport.map(
                (materialabtransport, indexMaterialabtransport) => (
                  <Box key={indexMaterialabtransport} sx={{ marginTop: 2 }}>
                    <Box
                      display={"grid"}
                      sx={{
                        gridTemplateColumns: ["1fr", "10fr .1fr"],
                        gap: 2,
                      }}
                    >
                      <Box
                        id={`fuhrparkeintraege.${index}.materialabtransport.${indexMaterialabtransport}`}
                      >
                        <MaterialabtransportFieldsContent
                          index={index}
                          materialTransportIndex={indexMaterialabtransport}
                          push={push}
                          remove={remove}
                          newestIndex={
                            fuhrparkeintrag.materialabtransport.length - 1
                          }
                          materialtransport={materialabtransport}
                          fahrzeugId={
                            typeof fuhrparkeintrag.fahrzeug === "string"
                              ? fuhrparkeintrag.fahrzeug
                              : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? ""
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              )
            ) : (
              <></>
            )}
          </>
        )}
      </FieldArray>
    </Stack>
  );
}

function NeuerMaschinentransportButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const { t } = useT("fuhrpark");
  const handleAddMaschinentransport = () => {
    const newMaterialanfuhr = {
      transportiertesFahrzeug: "",
      abholortBaustelle: "",
      abstellortBaustelle: "",
      lieferscheinNummer: "",
      bemerkung: "",
    };

    // Get the current array of materialanfuhr
    const currentMaterialanfuhr = values.maschinentransporte || [];

    // Set the new value using setFieldValue
    setFieldValue(`fuhrparkeintraege.${index}.maschinentransporte`, [
      ...currentMaterialanfuhr,
      newMaterialanfuhr,
    ]);
  };
  return (
    <Tooltip title={t("machine-transport")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaschinentransport}
      >
        <LocalShippingIcon />
      </Button>
    </Tooltip>
  );
}

function NeuerMaterialtransportZwischenBaustellenButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const getfahrzeug = useGetFahrzeug();
  const { t } = useT("fuhrpark");
  const [currentFahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  const handleAddMatrialtransportZwischenBaustellen = () => {
    const newMaterialanfuhr = {
      meLadeVolumenFahrzeug: currentFahrzeugLadeMenge ?? 0,
      transportiertesFahrzeug: "",
      abholortBaustelle: "",
      abstellortBaustelle: "",
      lieferscheinNummer: "",
      bemerkung: "",
      buchungsArt: "Kosten",
      anzahlFuhren: 1,
    };

    // Get the current array of materialanfuhr
    const currentMaterialanfuhr = values.materialtransport || [];
    setFieldValue(`fuhrparkeintraege.${index}.materialtransport`, [
      ...currentMaterialanfuhr,
      newMaterialanfuhr,
    ]);
  };
  return (
    <Tooltip title={t("material-transport-between-sites")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMatrialtransportZwischenBaustellen}
      >
        <FoundationIcon />
        <KeyboardDoubleArrowRightIcon />
        <FoundationIcon />
      </Button>
    </Tooltip>
  );
}
interface NeuerMaterialButtonProps {
  values: ExtendedFuhrparkeintragDaten;
  index: number;
  fahrzeugId: string;
}
function NeuerMaterialanfuhrButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const getfahrzeug = useGetFahrzeug();
  const { t } = useT("fuhrpark");
  const [currentFahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  const handleAddMaterialanfuhr = () => {
    const newMaterialanfuhr = {
      transportiertesFahrzeug: "",
      abholortBaustelle: "",
      abstellortBaustelle: "",
      meLadeVolumenFahrzeug: currentFahrzeugLadeMenge ?? 0,
      lieferscheinNummer: "",
      bemerkung: "",
      buchungsArt: "Kosten",
    };

    // Get the current array of materialanfuhr
    const currentMaterialanfuhr = values.materialanfuhr || [];

    // Set the new value using setFieldValue
    setFieldValue(`fuhrparkeintraege.${index}.materialanfuhr`, [
      ...currentMaterialanfuhr,
      newMaterialanfuhr,
    ]);
  };

  return (
    <Tooltip title={t("material-delivery")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaterialanfuhr}
      >
        <CategoryIcon />
        <KeyboardDoubleArrowRightIcon />
        <FoundationIcon />
      </Button>
    </Tooltip>
  );
}

function NeuerMaterialabtransportButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const getfahrzeug = useGetFahrzeug();
  const { t } = useT("fuhrpark");
  const [currentFahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  const handleAddMaterialabtransport = () => {
    const newMaterialanfuhr = {
      fuhrTyp: "AB",
      buchungsTyp: "AB",
      transportiertesFahrzeug: "",
      abholortBaustelle: "",
      abstellortBaustelle: "",
      lieferscheinNummer: "",
      bemerkung: "",
      buchungsArt: "Kosten",
      meLadeVolumenFahrzeug: currentFahrzeugLadeMenge ?? 0,
    };
    // Get the current array of materialanfuhr
    const currentMaterialanfuhr = values.materialabtransport || [];

    // Set the new value using setFieldValue
    setFieldValue(`fuhrparkeintraege.${index}.materialabtransport`, [
      ...currentMaterialanfuhr,
      newMaterialanfuhr,
    ]);
  };
  return (
    <Tooltip title={t("material-removal")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaterialabtransport}
      >
        <FoundationIcon />
        <KeyboardDoubleArrowRightIcon />
        <CategoryIcon />
      </Button>
    </Tooltip>
  );
}
