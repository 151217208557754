import {
  Box,
  Button,
  capitalize,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React, { useEffect, useState } from "react";
import {
  useDeleteMaterialtransport,
  useGetAbladestelle,
  useGetFahrzeug,
  useGetMaterial,
  useGetMaterialCosts,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  ActionTimestamp,
  Buchungstyp,
  EinheitenListe,
  Material,
} from "../../../../api";
import AbladestellenSelect from "../../../abladestellen/AbladestellenSelect";
import Buchungsart from "../../../Buchungsart";
import EntsorgerSelect from "../../../entsorger/EntsorgerSelect";
import ErzeugerSelect from "../../../erzeuger/ErzeugerSelect";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import MaterialSelect from "../../../material/MaterialSelect";

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelleId?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
}

export default function MaterialabtransportFieldsContent({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const deleteMaterialtransport = useDeleteMaterialtransport();
  const getAbladestelle = useGetAbladestelle();
  const getMaterial = useGetMaterial();
  const [isDeponie, setIsDeponie] = useState<boolean>(
    materialtransport.fuhrTyp === "DP"
  );
  const [isKosten] = useState(materialtransport.buchungsArt === "Kosten");
  const getfahrzeug = useGetFahrzeug();
  const [currentfahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  const [abladestelleId, setAbladestelleId] = useState<string | null>(
    materialtransport?.zielAbladestelle ?? null
  );

  useEffect(() => {
    setAbladestelleId(abladestelleId);
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`,
      null
    );
  }, [setAbladestelleId, abladestelleId]);

  const { setFieldValue } = useFormikContext();
  const [mengeField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.menge`
  );
  const [preisField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`
  );
  const [fuhrenField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.anzahlFuhren`
  );
  const [einheitField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.einheit`
  );
  const [materialField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`
  );
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  useEffect(() => {
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.mengeGesamt`,
      mengeField.value * fuhrenField.value
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.preisGesamt`,
      (mengeField.value * fuhrenField.value * preisField.value).toFixed(2)
    );
  }, [mengeField.value, fuhrenField.value, preisField.value]);
  useEffect(() => {
    if (abladestelleId && !materialtransport.id) {
      getAbladestelle(abladestelleId).then((abladestelle) => {
        if (abladestelle.isDeponie) {
          setIsDeponie(abladestelle.isDeponie);
          setFieldValue(
            `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.fuhrTyp`,
            abladestelle.isDeponie ? "DP" : "AB"
          );
          setFieldValue(
            `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsTyp`,
            abladestelle.isDeponie ? "DP" : "AB"
          );
        }
      });
    }
  }, [abladestelleId]);
  useEffect(() => {
    if (!materialtransport.id) {
      if (einheitField.value === "TO") {
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          )
            .then((material: Material) => {
              if ((material?.kostenProTonne ?? 0) > 0) {
                setFieldValue(
                  `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`,
                  material.kostenProTonne ?? ""
                );
              }
            })
            .catch((error) => {
              console.error("Error loading material:", error);
            });
        }
      }
      if (einheitField.value === "CB") {
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          ).then((material: Material) => {
            if ((material?.kostenProKubikmeter ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`,
                material.kostenProKubikmeter ?? ""
              );
            }
          });
        }
      }
    }
  }, [einheitField.value, materialField.value]);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-removal")}
      </Stack>

      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "5fr 4fr 4fr 4fr 1fr 1fr"],
          gap: 2,
        }}
      >
        {materialtransport.quelleBaustelle ? (
          <FastField
            component={BaustellenSelect}
            name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.quelleBaustelle`}
            label={"Abladestelle"}
            required
          />
        ) : (
          <FastField
            component={AbladestellenSelect}
            name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.zielAbladestelle`}
            label={"Abladestelle"}
            required
            setAbladestelleId={setAbladestelleId}
          />
        )}
        <FastField
          component={TextField}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.meLadeVolumenFahrzeug`}
          label={"ME (Ladevolumen Fahrzeug)"}
          required
          type={"number"}
          inputProps={{ step: 1 }}
        />
        {abladestelleId ? (
          <FastField
            component={MaterialSelect}
            name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`}
            label={"Material"}
            abladestelleId={abladestelleId}
            required
          />
        ) : (
          <FastField
            component={Select}
            name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`}
            label={"Material *"}
            disabled={true}
          />
        )}
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Switch
              checked={isDeponie}
              onChange={(e) => {
                setIsDeponie(e.target.checked);
                setFieldValue(
                  `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.fuhrTyp`,
                  e.target.checked ? "DP" : "AB"
                );
                setFieldValue(
                  `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsTyp`,
                  e.target.checked ? "DP" : "AB"
                );
              }}
            />
          }
          label={"Ist Deponie"}
        />

        {materialTransportIndex === newestIndex ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              push({
                fuhrTyp: "AB",
                buchungsTyp: "AB",
                zielAbladestelle: "",
                meLadeVolumenFahrzeug: currentfahrzeugLadeMenge ?? 0,
                anzahlFuhren: "",
                lieferscheinNummer: "",
                bemerkung: "",
                buchungsArt: "Kosten",
              });
            }}
          >
            <AddCircleIcon />
          </Button>
        ) : (
          <></>
        )}
        <IconButton
          onClick={() => {
            const materialransportId = materialtransport.id;
            if (materialransportId) {
              deleteMaterialtransport(
                materialtransport.taetigkeitsberichtId,
                materialtransport.fuhrparkeintragId,
                materialransportId
              ).then(() => {
                remove(materialTransportIndex);
              });
            } else {
              remove(materialTransportIndex);
            }
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "5fr 4fr 4fr 3fr 1fr 1fr"],
          gap: 2,
          mt: 2,
        }}
      >
        <FastField
          component={TextField}
          label={"Preis"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`}
          required
          type={"number"}
          inputProps={{ step: 0.01 }}
          InputLabelProps={{ shrink: !!preisField.value }}
        />
        <FastField
          component={TextField}
          label={"Bs-Nr"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bsNr`}
        />
        <FastField
          component={ErzeugerSelect}
          label={"EKZ-Nr"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.erzeuger`}
        />
        <FastField
          component={EntsorgerSelect}
          label={"EN-Nr"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.entsorger`}
        />
      </Box>

      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1.5fr 1fr 2fr 2fr 2fr 1fr"],
          mt: 2,
          gap: 2,
        }}
      >
        <FastField
          component={TextField}
          label={"Anzahl Fuhren"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.anzahlFuhren`}
          required
          type={"number"}
          inputProps={{ step: 1 }}
        />
        <FastField
          component={Select}
          label={"Einheit *"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.einheit`}
          required
        >
          <MenuItem value={"CB"}>CBM</MenuItem>
          <MenuItem value={"TO"}>TO</MenuItem>
          <MenuItem value={"SK"}>STÜCK</MenuItem>
          <MenuItem value={"PA"}>PAUSCHAL</MenuItem>
        </FastField>
        <FastField
          component={TextField}
          label={"Menge"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.menge`}
          required
          type={"number"}
          inputProps={{ step: 1 }}
        />
        <FastField
          component={TextField}
          label={"Gesamtmenge"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.mengeGesamt`}
          type={"number"}
          inputProps={{ step: 0.01, readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={"Gesamtpreis"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.preisGesamt`}
          type={"number"}
          inputProps={{ step: 0.01, readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled={true}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "3fr 5fr 1fr"],
          mt: 2,
          gap: 2,
        }}
      >
        <FastField
          component={TextField}
          label={"LFS-Nr"}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.lieferscheinNummer`}
        />
        <FastField
          component={TextField}
          label={capitalize(t("vehicle-transport-remark"))}
          name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bemerkung`}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "3fr 2fr 2fr 1fr"],
          mt: 2,
          gap: 2,
        }}
      ></Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}
