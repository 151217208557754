import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchField from "../SearchField";
import { useEffect, useState } from "react";
import {
  AbfallartDaten,
  DeponieDaten,
  DeponieInfo,
  DeponieListe,
} from "../../api";
import {
  useCreateAbfallart,
  useSucheMaterialtransporteDeponie,
  useUpdateMaterialtransportDeponie,
} from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";

export default function BaustellenEintragDeponien({
  baustelleId,
  rechnungId,
}: {
  baustelleId: string | undefined;
  rechnungId: string | undefined;
}) {
  const [deponien, setDeponien] = useState<DeponieListe>();
  const [search, setSearch] = useState<string>("");
  const searchDeponien = useSucheMaterialtransporteDeponie();
  const { mutate } = useSaveDeponie();
  useEffect(() => {
    const fetchDeponien = async () => {
      if (baustelleId) {
        const deponien = await searchDeponien({
          search: search,
          baustelleId: baustelleId,
          rechnungId: rechnungId,
        });
        deponien.items.forEach((deponie: DeponieInfo) => {
          if (deponie.rechnungId !== undefined) {
            setCheckedItems((prev) => ({
              ...prev,
              [deponie.id]: true,
            }));
          }
        });
        setDeponien(deponien);
      }
    };
    fetchDeponien();
  }, [baustelleId, search]);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  const handleToggle = (id: any) => {
    const currentChecked = checkedItems[id] || false;
    const newRechnungId = currentChecked ? "" : rechnungId ?? "";
    const checked = mutate({
      materialtransportId: id,
      rechnungId: newRechnungId ?? "",
    });
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !currentChecked,
    }));
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        Tätigkeitsberichte
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <SearchField
            value={search}
            onChange={(value) => {
              setSearch(value);
            }}
          ></SearchField>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Zuordnen</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Abladestelle</TableCell>
                <TableCell>Menge</TableCell>
                <TableCell>Menge Gesamt</TableCell>
                <TableCell>Einheiten</TableCell>
                <TableCell>Anzahl Fahrten</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deponien ? (
                deponien.items.map((deponie) => (
                  <TableRow>
                    <TableCell padding="none">
                      <Checkbox
                        checked={checkedItems[deponie.id]}
                        onChange={() => handleToggle(deponie.id)}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      {new Date(deponie.erstelltUm ?? "").toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.kurzname}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.menge}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.mengeGesamt}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.einheit}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.anzahlFuhren}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
function useSaveDeponie() {
  const updateDeponie = useUpdateMaterialtransportDeponie();
  const queryClient = useQueryClient();

  return useMutation(
    (input: DeponieDaten) => {
      return updateDeponie(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["materials"]);
      },
    }
  );
}
