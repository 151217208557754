import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { capitalize, Paper, Typography } from "@mui/material";
import MyAnwesenheitenDataTable from "../../components/anwesenheiten/MyAnwesenheitenDataTable";

export default function MeineAnwesenheiten() {
  const { t } = useT("taetigkeitsbericht");
  const limit = parseInt(
    localStorage.getItem("meine-anwesenheitenLimit") ?? "25",
    10
  );
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">Meine Anwesenheiten</Typography>
        <MyAnwesenheitenDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
