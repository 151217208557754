/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Anwesenheit } from '../models/Anwesenheit';
import type { AnwesenheitDaten } from '../models/AnwesenheitDaten';
import type { AnwesenheitenListe } from '../models/AnwesenheitenListe';
import type { MyAnwesenheitDaten } from '../models/MyAnwesenheitDaten';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AnwesenheitenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Anwesenheiten.
   * @returns AnwesenheitenListe Die Seite der Anwesenheiten.
   * @throws ApiError
   */
  public sucheAnwesenheiten({
    search,
    mitarbeiterId,
    page,
    limit,
    sort,
  }: {
    search?: string,
    mitarbeiterId?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AnwesenheitenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/anwesenheiten',
      query: {
        'search': search,
        'mitarbeiterId': mitarbeiterId,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Anwesenheit Die angelegte Anwesenheit
   * @throws ApiError
   */
  public createAnwesenheit({
    requestBody,
  }: {
    requestBody: AnwesenheitDaten,
  }): CancelablePromise<Anwesenheit> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/anwesenheiten',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Anwesenheiten des angemeldeten Benutzers.
   * @returns AnwesenheitenListe Die Seite der Anwesenheiten.
   * @throws ApiError
   */
  public sucheMeineAnwesenheiten({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AnwesenheitenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/anwesenheiten/my',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Anwesenheit Die angelegte Anwesenheit
   * @throws ApiError
   */
  public createMeineAnwesenheit({
    requestBody,
  }: {
    requestBody: AnwesenheitDaten,
  }): CancelablePromise<Anwesenheit> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/anwesenheiten/my',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Anwesenheit Die Anwesenheit
   * @throws ApiError
   */
  public getAnwesenheit({
    anwesenheitId,
  }: {
    anwesenheitId: string,
  }): CancelablePromise<Anwesenheit> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/anwesenheiten/{anwesenheitId}',
      path: {
        'anwesenheitId': anwesenheitId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Anwesenheit Die aktualisierte Anwesenheit
   * @throws ApiError
   */
  public updateAnwesenheit({
    anwesenheitId,
    requestBody,
  }: {
    anwesenheitId: string,
    requestBody: AnwesenheitDaten,
  }): CancelablePromise<Anwesenheit> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/anwesenheiten/{anwesenheitId}',
      path: {
        'anwesenheitId': anwesenheitId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Anwesenheit Die gelöschte Anwesenheit
   * @throws ApiError
   */
  public deleteAnwesenheit({
    anwesenheitId,
  }: {
    anwesenheitId: string,
  }): CancelablePromise<Anwesenheit> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/anwesenheiten/{anwesenheitId}',
      path: {
        'anwesenheitId': anwesenheitId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Anwesenheit Die aktualisierte Anwesenheit
   * @throws ApiError
   */
  public updateMeineAnwesenheit({
    anwesenheitId,
    requestBody,
  }: {
    anwesenheitId: string,
    requestBody: MyAnwesenheitDaten,
  }): CancelablePromise<Anwesenheit> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/anwesenheiten/my/{anwesenheitId}',
      path: {
        'anwesenheitId': anwesenheitId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
