import {
  Box,
  Button,
  capitalize,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../../data";
import { useT } from "../../../i18n";
import SearchField from "../../SearchField";
import DataTable from "../../data/DataTable";
import { useGetApi } from "../../../client";
import { HerkunftTyp, Timestamp, FahrzeugGruppe } from "../../../api";

export type FahrzeugFilter = {
  search?: string;
};

export type Fahrzeug = {
  id: string;
  gruppe: FahrzeugGruppe;
  herkunft: HerkunftTyp;
  fabrikant?: string;
  fahrzeugTyp?: string;
  laufendeNummer?: string;
  kennzeichen?: string;
  angeschafft?: Timestamp;
  breite?: number;
  tiefe?: number;
  hoehe?: number;
  gesamtMasse?: number;
  lautstaerke?: number;
  kostenStundeAktuell: number;
  ladeMenge?: number;
};

export type Props = Omit<DataRequest<FahrzeugFilter>, "filter"> &
  Partial<Pick<DataRequest<FahrzeugFilter>, "filter">>;

export default function FahrzeugDataTable({ ...input }: Props) {
  const request = useDataRequest<FahrzeugFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <FahrzeugResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<FahrzeugFilter>) {
  const { t } = useT("fuhrpark");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neues-fahrzeug");
            }}
          >
            {capitalize(t("create-vehicle"))}
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neues-fahrzeug");
            }}
          >
            {capitalize(t("create-vehicle"))}
          </Button>
        </>
      )}
    </Box>
  );
}

function FahrzeugResults({ ...request }: DataRequestState<FahrzeugFilter>) {
  return <Results request={request} />;
}
function Results({ request }: { request: DataRequestState<FahrzeugFilter> }) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const ladeFahrzeuge: DataLoader<FahrzeugFilter, Fahrzeug> = useCallback(
    async (params) => (await getApi()).fahrzeuge.sucheFahrzeuge({ ...params }),
    [getApi]
  );

  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["fahrzeuge"]}
      loadData={ladeFahrzeuge}
      onRowClick={(row) => {
        navigate("/fahrzeuge/" + row.id);
      }}
      name={"fahrzeuge"}
    />
  );
}

function useColumns(): Array<GridColDef<Fahrzeug>> {
  const { t, capitalize } = useT("fuhrpark");

  return useMemo(
    () => [
      {
        field: "gruppe",
        headerName: capitalize(t("vehicle-group")),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return capitalize(params.value.toLowerCase());
          }
          return "";
        },
      },
      {
        field: "herkunft",
        headerName: capitalize(t("vehicle-origin")),
        flex: 1,
        renderCell: (params) => {
          if (params.value === "EIGEN_GERAET") {
            return "Eigenes Gerät";
          } else {
            return "Fremdgerät";
          }
        },
      },
      {
        field: "fabrikant",
        headerName: capitalize(t("vehicle-make")),
        flex: 1,
      },
      {
        field: "fahrzeugTyp",
        headerName: capitalize(t("vehicle-vehicle-type")),
        flex: 1,
      },
      {
        field: "laufendeNummer",
        headerName: capitalize(t("vehicle-current-number")),
        flex: 1,
      },
      {
        field: "kennzeichen",
        headerName: capitalize(t("vehicle-mark")),
        flex: 1,
      },
      {
        field: "angeschafft",
        headerName: capitalize(t("vehicle-purchased")),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString();
          }
          return "";
        },
      },
      {
        field: "breite",
        headerName: capitalize(t("vehicle-width")),
        flex: 1,
      },
      {
        field: "tiefe",
        headerName: capitalize(t("vehicle-depth")),
        flex: 1,
      },
      {
        field: "hoehe",
        headerName: capitalize(t("vehicle-height")),
        flex: 1,
      },
      {
        field: "gesamtMasse",
        headerName: capitalize(t("vehicle-total-mass")),
        flex: 1,
      },
      {
        field: "lautstaerke",
        headerName: capitalize(t("vehicle-loudness")),
        flex: 1,
      },
      {
        field: "ladeMenge",
        headerName: capitalize(t("vehicle-loading-quantity")),
        flex: 1,
      },
      {
        field: "kostenStundeAktuell",
        headerName: capitalize(t("vehicle-cost-hour")),
        flex: 1,
      },
    ],
    [t, capitalize]
  );
}
