import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import DataTable from "../data/DataTable";
import { Lieferant } from "../../api";

export type LieferantenFilter = {
  search?: string;
};

export type Props = Omit<DataRequest<LieferantenFilter>, "filter"> &
  Partial<Pick<DataRequest<LieferantenFilter>, "filter">>;

export default function LieferantenDataTable({ ...input }: Props) {
  const request = useDataRequest<LieferantenFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <LieferantenResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<LieferantenFilter>) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-lieferant");
            }}
          >
            Lieferant anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-lieferant");
            }}
          >
            Lieferant anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function LieferantenResults({
  ...request
}: DataRequestState<LieferantenFilter>) {
  return <Results request={request} />;
}

function Results({
  request,
}: {
  request: DataRequestState<LieferantenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadLieferanten: DataLoader<LieferantenFilter, Lieferant> = useCallback(
    async (params) =>
      (await getApi()).lieferanten.sucheLieferanten({ ...params }),
    [getApi]
  );

  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["lieferanten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadLieferanten}
      onRowClick={(row) => {
        navigate("/lieferanten/" + row.id);
      }}
      name={"lieferanten"}
    />
  );
}
function useColumns(): Array<GridColDef<Lieferant>> {
  const { t } = useT("customer");

  return useMemo(
    () => [
      {
        field: "name",
        headerName: t("name"),
        flex: 1,
      },
      {
        field: "strasse",
        headerName: t("street"),
        flex: 1,
      },
      {
        field: "plz",
        headerName: t("plz"),
        flex: 1,
      },
      {
        field: "ort",
        headerName: t("city"),
        flex: 1,
      },
      {
        field: "telefon",
        headerName: t("phone"),
        flex: 1,
      },
      {
        field: "email",
        headerName: t("email"),
        flex: 1,
      },
      {
        field: "kundennummer",
        headerName: t("customer-number"),
        flex: 1,
      },
    ],
    [t]
  );
}
