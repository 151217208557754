import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { Paper, Typography } from "@mui/material";
import AusgangsrechnungenDataTable from "../../components/ausgangsrechnungen/AusgangsrechnungenDataTable";

export default function AusgangsrechnungenListe() {
  const { t } = useT("ausgangsrechnung");
  const limit = parseInt(
    localStorage.getItem("ausgangsrechnungenLimit") ?? "25",
    10
  );
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">{t("outgoing-invoices")}</Typography>
        <AusgangsrechnungenDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
