import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import ErzeugerDataTable from "../../components/erzeuger/ErzeugerDataTable";

export default function ErzeugerListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("erzeugerLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">Erzeuger</Typography>
        <ErzeugerDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
