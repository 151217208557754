import { capitalize, Paper, Typography } from "@mui/material";
import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import TaetigkeitsberichtDataTableExtern from "../../components/taetigkeitsberichte/TaetigkeitsberichtDataTableExtern";

export default function TaetigkeitsberichteExtern() {
  const { t } = useT("taetigkeitsbericht");
  const limit = parseInt(
    localStorage.getItem("taetigkeitsberichte-externLimit") ?? "25",
    10
  );
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">
          {capitalize(t("activity-reports-external"))}
        </Typography>
        <TaetigkeitsberichtDataTableExtern limit={limit} />
      </Paper>
    </Layout>
  );
}
