import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import LieferantenDataTable from "../../components/lieferanten/LieferantenDataTable";
import { useT } from "../../i18n";

export default function LieferantenListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("lieferantenLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">{t("suppliers")}</Typography>
        <LieferantenDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
