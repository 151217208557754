import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { capitalize, Paper, Typography } from "@mui/material";
import MyKrankmeldungenDataTable from "../../components/krankmeldungen/MyKrankmeldungenDataTable";

export default function MeineKrankmeldungen() {
  const { t } = useT("abwesenheit");
  const limit = parseInt(
    localStorage.getItem("meine-krankmeldungenLimit") ?? "25",
    10
  );
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">{t("my-sickness")}</Typography>
        <MyKrankmeldungenDataTable />
      </Paper>
    </Layout>
  );
}
