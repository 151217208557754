import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { Paper, Typography } from "@mui/material";
import BaustellenDataTable from "../../components/baustellen/BaustellenDataTable";

export default function BaustellenListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("baustellenLimit") ?? "25", 10);
  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Typography variant="h5">{t("construction-site_other")}</Typography>
        <BaustellenDataTable limit={limit} />
      </Paper>
    </Layout>
  );
}
